import { Link } from "gatsby";
import React from "react";
import FancyTitle from "../FancyTitle/FancyTitle";

const BlogSidebar = ({ latestBlogs, categories }) => {
  return (
    <div className="blog-sidebar">
      <div>
        <div className="blog-sidebar__title">
          <FancyTitle title={"最新の投稿"} />
        </div>
        <div
          className={`blog-sidebar__latest-blogs ${
            categories ? "mb-14" : "mb-0"
          }`}
        >
          {latestBlogs.map((latestBlog, index) => {
            return latestBlog?.categories?.data?.length > 0 ? (
              <Link
                to={`/blog/${latestBlog?.categories?.data[0]?.attributes?.slug}/${latestBlog?.slug}/`}
                key={latestBlog?.id}
              >
                {latestBlog?.title}
              </Link>
            ) : (
              <Link
                to={`/news/${latestBlog?.attributes?.slug}/`}
                key={latestBlog?.id || index}
              >
                {latestBlog?.title || latestBlog?.attributes?.title}
              </Link>
            );
          })}
        </div>
      </div>
      {categories?.length > 0 && (
        <div>
          <div className="blog-sidebar__title">
            <FancyTitle title={"カテゴリ"} />
          </div>
          <div className="blog-sidebar__categories">
            {categories.map((category) => (
              <Link
                to={`/blog/${category?.attributes?.slug}/`}
                key={category.id}
              >
                <p className="blog-sidebar__categories__item">
                  {category?.attributes?.name}
                </p>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogSidebar;
