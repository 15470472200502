import React from "react";
import { Link } from "gatsby";
import parse, { domToReact } from "html-react-parser";
import { blogDetailsAliasUrls } from "../alias-urls/blogDetails";
import { extractInternalLink } from "./helpers";

export const serializeContent = (htmlString, assetsBaseUrl) => {
  var imageCounter = 0;
  return parse(htmlString, {
    replace: (node) => {
      if (node.type === "tag" && node.name === "a") {
        var href = node.attribs.href;

        // Alias Links Mapper
        if (blogDetailsAliasUrls.includes(href)) {
          href = "/inquiry/?from-blog";
        }

        // Check internal link or External link
        const extractedHref = extractInternalLink(href);
        if (extractedHref.startsWith("/")) {
          return <Link to={extractedHref}>{domToReact(node.children)}</Link>;
        } else {
          node.attribs.target = "_blank";
        }
      } else if (node.type === "tag" && node.name === "img") {
        node.attribs.src =
          node.attribs?.src && node.attribs.src.replace(/^/g, assetsBaseUrl);
        node.attribs.srcset =
          node.attribs?.srcset &&
          node.attribs.srcset.replace(
            /\/uploads\//g,
            `${assetsBaseUrl}/uploads/`
          );
        node.attribs.loading = ++imageCounter === 1 ? "eager" : "lazy";
      }
    },
  });
};
