import React, { useEffect } from "react";
import Container from "../../layout/container";
import { graphql, Link } from "gatsby";
import BlogSidebar from "../../components/blog-details/Sidebar/BlogSidebar";
import Meta from "../../components/seo/Meta";
import hljs from "highlight.js";
import { serializeContent } from "../../utils/customParser";
const BlogDetails = (props) => {
  const { data } = props;
  const blogDetails = data?.allBlogs?.nodes[0];
  const categories = blogDetails?.categories?.data;
  const latestBlogs = data?.latestBlogs?.nodes;
  const { pageContext } = props;
  const siteUrl = props?.data?.allSite?.nodes[0]?.siteMetadata?.siteUrl;
  const assetsBaseUrl =
    props?.data?.allSite?.nodes[0]?.siteMetadata?.assetsBaseUrl;

  const metaData = {
    title: `${blogDetails.title}｜Harness正規販売代理店 DXable`,
    image: `${assetsBaseUrl}${blogDetails?.seo?.feature_image?.data?.attributes?.url}`,
    url: `${siteUrl}${props.path}`,
    description: blogDetails.seo?.description || "",
  };

  useEffect(() => {
    hljs.highlightAll();
  }, []);

  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <div className="py-0 hn-container">
          <div className="blog-details">
            <div className="blog-details__body">
              <div className="blog-details__body__sub-data">
                <p className="blog-details__body__sub-data__date">
                  {blogDetails?.releaseDate}
                </p>
                <div className="blog-details__body__sub-data__categories">
                  {categories?.map((category) => (
                    <p
                      className="blog-details__body__sub-data__categories__category"
                      key={category?.attributes?.name}
                    >
                      {category?.attributes?.name}
                    </p>
                  ))}
                </div>
              </div>
              <h1 className="blog-details__body__title">
                {blogDetails?.title}
              </h1>
              <div className="blog-details__body__wrapper">
                <div className="blog-details__body__wrapper__content">
                  {serializeContent(blogDetails?.content, assetsBaseUrl)}
                  <div className="blog-details__body__wrapper__content__cta">
                    {pageContext?.previousPageId !== "" && (
                      <Link to={`${pageContext?.previousPagePath}`}>
                        &lt; 前へ
                      </Link>
                    )}
                    {pageContext?.nextPageId !== "" && (
                      <Link
                        to={`${pageContext?.nextPagePath}`}
                        className="ml-auto"
                      >
                        次へ &gt;
                      </Link>
                    )}
                  </div>
                </div>
                <div className="blog-details__body__wrapper__sidebar">
                  <BlogSidebar
                    latestBlogs={latestBlogs}
                    categories={data?.strapi?.categories?.data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default BlogDetails;

export const query = graphql`
  query BlogDetails($pageId: String!) {
    allBlogs(filter: { slug: { eq: $pageId } }) {
      nodes {
        id
        slug
        title
        releaseDate(formatString: "LL", locale: "ja")
        content
        featuredImage {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
          }
        }
        categories {
          data {
            attributes {
              name
              slug
            }
          }
        }
        seo {
          feature_image {
            data {
              attributes {
                alternativeText
                caption
                url
              }
            }
          }
          id
          title
          description
        }
      }
    }
    latestBlogs: allBlogs(
      sort: { fields: releaseDate, order: DESC }
      limit: 10
    ) {
      nodes {
        id
        slug
        title
        categories {
          data {
            attributes {
              slug
            }
          }
        }
      }
    }
    strapi {
      categories {
        data {
          id
          attributes {
            name
            slug
          }
        }
      }
    }
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;
