import React from "react";

const FancyTitle = ({ title }) => {
  return (
    <div className="fancy-title">
      <p className="fancy-title__text">{title}</p>
      <div className="fancy-title__fancy-underline">
        <div className="fancy-title__fancy-underline__line"></div>
        <div className="fancy-title__fancy-underline__circle"></div>
        <div className="fancy-title__fancy-underline__circle"></div>
      </div>
    </div>
  );
};

export default FancyTitle;
