// Target Url -> /inquiry/?from-blog

export const blogDetailsAliasUrls = [
  "https://app.harness.io/auth/#/signup",
  "https://harness.io/company/contact-sales",
  "https://harness.io/technical-blog/harness-ci-aws-vms",
  "https://harness.io/products/cloud-cost",
  "https://harness.io/demo/gitops",
  "https://harness.io/demo/sto",
  "https://app.harness.io/auth/#/signup/",
  "https://app.harness.io/auth/#/signup/?module=ci",
  "https://harness.io/demo/next-gen",
  "https://app.harness.io/auth/#/signup/?module=ff",
  "https://app.harness.io/auth/#/signup/?module=cd",
  "https://app.harness.io/auth/#/signup/?module=cf",
  "https://app.harness.io/auth/#/signin",
  "https://app.harness.io/auth/#/signup/?module=ce",
  "https://harness.io/company/contact-sales?utm_source=Website&utm_medium=Mutiny&utm_campaign=Mutiny-Nav-Contact-Sales-vs-Get-Pricing&utm_content=Get-Pricing",
  "https://www.harness.io/demo/gitops",
  "https://harness.io/blog/announcing-speed-enhancements-and-hosted-builds-for-harness-ci",
  "https://harness.io/demo/chaos-engineering",
  "https://harness.io/trial/che-on-prem",
  "https://harness.io/demo/feature-flag",
  "https://harness.io/products/continuous-integration",
  "https://harness.digitalstacks.net/inquiry/",
  "https://harness.dxable.com/inquiry/",
];
